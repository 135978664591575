import React, { useState, useContext, useEffect } from "react";
import { Col, Container, Row, Nav, NavDropdown } from "react-bootstrap";
import "swiper/css/bundle";
import MatchesCard from "@/components/cards/matches";
import api from "@/axios";
import Masonry from "react-masonry-css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, EffectFade } from "swiper";
import Tab from "react-bootstrap/Tab";
import { useQueryParam, StringParam } from "use-query-params";
import { SearchContext } from "@/context/search-context";

import sliderTop01 from "@/images/bg/news/top.jpg";

SwiperCore.use([Autoplay, EffectFade]);

const NewsPage = () => {
  const breakpointColumnsObj = {
    default: 5,
    1600: 3,
    1000: 2,
    875: 1,
  };

  const { searchStatus, updateSearchStatus } = useContext(SearchContext);
  const [paramQuerySearch, setParamId] = useQueryParam("q", StringParam);
  const [apiData, setData] = useState(null);
  const [apiFullData, setFullData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [galleryData, setGalleryData] = useState(null);
  const [moviesData, setMoviesData] = useState(null);
  const [firstEnter, setFirstEnter] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.get(`/search`, {
          params: { query: paramQuerySearch, publicationPage: "Strona główna" },
        });
        setFullData(response.data);
        setData(response.data.news);

        setError(null);
      } catch (err) {
        setError(err.message);
        setFullData(null);
        setData(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
    if(!firstEnter) {
      setFirstEnter(true);
      updateSearchStatus(searchStatus);
    } else updateSearchStatus(!searchStatus);
  }, [paramQuerySearch]);

  const mainSlideOptions = {
    slidesPerView: 1,
    loop: false,
    effect: "fade",
  };

  function handleSelect(e) {
    if (e == "gallery") {
      setGalleryData(apiFullData.galleries);
    } else if (e == "movies") {
      setMoviesData(apiFullData.movies);
    }
  }

  return (
    <>
      <section className="main-slider slider-subpages slider-subpages-manual">
        <Swiper {...mainSlideOptions}>
          <SwiperSlide>
            <div
              className="image-layer"
              style={{
                backgroundImage: `url('${sliderTop01}')`,
              }}
            ></div>
            <Container className="w-auto animated fadeInUp">
              <Row>
                <Col lg={12} className="text-center">
                  <h3 className="main-slider__title">SZUKAJ</h3>
                </Col>
              </Row>
            </Container>
          </SwiperSlide>
        </Swiper>
      </section>
      <section className="commonSection news-page-tabs-section">
        <Container>
          <Row>
            <Col lg={12} className="text-center">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="news"
                onSelect={e => handleSelect(e)}
              >
                <Container className="py-4 px-5 text-center">
                  <Row>
                    <Col xl={12} className="d-none d-xl-block">
                      <Nav className="justify-content-center">
                        <Nav.Item className="mx-2">
                          <Nav.Link eventKey="news">AKTUALNOŚCI</Nav.Link>
                        </Nav.Item>
                        <Nav.Item key="gallery" className="mx-2">
                          <Nav.Link eventKey="gallery">FOTOGALERIE</Nav.Link>
                        </Nav.Item>
                        <Nav.Item key="movies" className="mx-2">
                          <Nav.Link eventKey="movies">MATERIAŁY WIDEO</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col className="d-block d-xl-none col-auto mx-auto">
                      <NavDropdown title="Filtr kategorii" id="nav-dropdown">
                        <NavDropdown.Item eventKey="news">
                          Aktualności
                        </NavDropdown.Item>
                        <NavDropdown.Item eventKey="gallery">
                          Fotogalerie
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Col>
                  </Row>
                </Container>
                <Container className="mt-5" style={{ zIndex: 1 }}>
                  <Tab.Content className="mt-3">
                    <Tab.Pane eventKey="news">
                      <Row className="animated fadeInUp">
                        <Masonry
                          breakpointCols={breakpointColumnsObj}
                          className="my-masonry-grid"
                          columnClassName="my-masonry-grid_column"
                        >
                          {loading && <span className="loader"></span>}
                          {error && (
                            <div className="text-dark">{`Wystąpił problem z wczytaniem wyników wyszukiwania - ${error}`}</div>
                          )}
                          {!loading &&
                            apiData &&
                            apiData.map((post, index) => (
                              <div
                                className="news-page-masonry-column"
                                key={index}
                              >
                                <MatchesCard data={post} />
                              </div>
                            ))}
                        </Masonry>
                      </Row>
                    </Tab.Pane>
                    {!loading && (
                      <Tab.Pane eventKey="gallery">
                        <Row className="animated fadeInUp">
                          <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                          >
                            {loading && <span className="loader"></span>}
                            {error && (
                              <div className="text-dark">{`Wystąpił problem z wczytaniem wyników wyszukiwania - ${error}`}</div>
                            )}
                            {!loading &&
                              galleryData &&
                              galleryData.map((post, index) => (
                                <div
                                  className="news-page-masonry-column"
                                  key={index}
                                >
                                  <MatchesCard data={post} />
                                </div>
                              ))}
                          </Masonry>
                        </Row>
                      </Tab.Pane>
                    )}
                    {!loading && (
                      <Tab.Pane eventKey="movies">
                        <Row className="animated fadeInUp">
                          <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                          >
                            {loading && <span className="loader"></span>}
                            {error && (
                              <div className="text-dark">{`Wystąpił problem z wczytaniem wyników wyszukiwania - ${error}`}</div>
                            )}
                            {!loading &&
                              moviesData &&
                              moviesData.map((post, index) => (
                                <div
                                  className="news-page-masonry-column"
                                  key={index}
                                >
                                  <MatchesCard data={post} />
                                </div>
                              ))}
                          </Masonry>
                        </Row>
                      </Tab.Pane>
                    )}
                  </Tab.Content>
                </Container>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default NewsPage;
